/* ContestPage.module.css */
.container {
  font-family: Arial, sans-serif;
  color: white;
  background-color: #0b0c2a;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #1a1b41;
}

.contestImage{
  width:100%;
}

.logo {
  color: #ff5a5f;
  font-size: 24px;
  text-decoration: none;
}

.navList {
  list-style: none;
  display: flex;
  gap: 20px;
}

.navList li a {
  color: white;
  text-decoration: none;
}

.mainContent {
  display: flex;
  justify-content: center;
  padding: 50px 20px;
}

.contentSection {
  display: flex;
  width: 100%;
  max-width: 1200px;
  gap: 20px;
}

.heroSection {
  flex: 2;
  background: linear-gradient(to right, #36d1dc, #5b86e5);
  padding: 40px;
  border-radius: 10px;
  position: relative; /* Set position to relative */
}

.heroSection h1 {
  font-size: 36px;
}

.heroSection p {
  font-size: 18px;
}

.registerButton {
  padding: 10px 20px;
  font-size: 16px;
  color: #0b0c2a;
  background-color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.calendarSection {
  flex: 1;
}

.calendarSection h2 {
  font-size: 28px;
  margin-bottom: 20px;
}

.contestsList {
  list-style: none;
  padding: 0;
}

.contestItem {
  background-color: #1a1b41;
  padding: 20px;
  margin: 10px 0;
  border-radius: 10px;
}

.date {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.description {
  font-size: 16px;
}

.knowMore {
  color: #ff5a5f;
  text-decoration: none;
  font-weight: bold;
}

.imageContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.contest {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the container */
  display: block;
}

.overlayText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}

.overlayText h1 {
  font-size: 36px;
  margin-bottom: 10px;
}

.overlayText p {
  font-size: 18px;
  margin-bottom: 20px;
}

.overlayText button {
  padding: 10px 20px;
  font-size: 16px;
  color: #0b0c2a;
  background-color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.contestImage {
  animation: moveImage 2s infinite alternate; /* Adjust animation duration and type as needed */
}

@keyframes moveImage {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(20px); /* Adjust movement distance as needed */
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: center;
  }
  
  .navList {
    flex-direction: column;
    gap: 10px;
  }
  
  .mainContent {
    flex-direction: column;
    padding: 20px 10px;
  }
  
  .contentSection {
    flex-direction: column;
    gap: 10px;
  }
  
  .heroSection {
    padding: 20px;
  }
  
  .heroSection h1 {
    font-size: 28px;
  }
  
  .heroSection p {
    font-size: 16px;
  }
  
  .calendarSection h2 {
    font-size: 24px;
  }
  
  .date {
    font-size: 20px;
  }
  
  .description {
    font-size: 14px;
  }
  
  .overlayText h1 {
    font-size: 28px;
  }
  
  .overlayText p {
    font-size: 16px;
  }
  
  .overlayText button {
    padding: 8px 16px;
    font-size: 14px;
  }
  
  .registerButton {
    position: static;
    transform: none;
    margin-top: 10px;
  }
}

@media (max-width: 480px) {
  .heroSection {
    padding: 10px;
  }
  
  .heroSection h1 {
    font-size: 24px;
  }
  
  .heroSection p {
    font-size: 14px;
  }
  
  .calendarSection h2 {
    font-size: 20px;
  }
  
  .date {
    font-size: 18px;
  }
  
  .description {
    font-size: 12px;
  }
  
  .overlayText h1 {
    font-size: 24px;
  }
  
  .overlayText p {
    font-size: 14px;
  }
  
  .overlayText button {
    padding: 6px 12px;
    font-size: 12px;
  }
}
