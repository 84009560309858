@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

.profile-card-container-unique {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f1f1f1;
}

.profile-card-unique {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 500px;
  text-align: center;
  overflow: hidden;
}

.profile-header-unique {
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 20px;
  max-width: 30px;
}

.profile-header-unique img {
  width: 50px;
}

.profile-name-unique {
  font-weight: bold;
}

.profile-image-unique {
  width: 100%;
  height: 200px; /* Fixed height for all images */
  object-fit: contain; /* Ensures the entire image is visible within the area */
  background-color: #f1f1f1; /* Background color for padding areas */
}

.profile-footer-unique {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.like-section-unique {
  display: flex;
  align-items: center;
  gap: 15px;
}

.like-section-unique i {
  margin-right: 8px;
}

.profile-button-unique {
  background-color: #4285f4;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
}

.profile-button-unique:hover {
  background-color: #357ae8;
}
