@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

/* Importing Montserrat */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');



.profile_sid123 .profile-page {
  font-family: 'Montserrat', sans-serif;
  }
  
  .profile_sid123 .header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background-color: #3a3a3a;
    color: white;
    padding: 10px 20px;
  }
  
  .profile_sid123 .logo {
    font-size: 24px;
    font-weight: bold;
  }
  
  .profile_sid123 .nav {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  .profile_sid123 .navbar-logo a{
    text-decoration: none;
    color: white;
  }
  
  .profile_sid123 .nav a {
    color: white;
    text-decoration: none;
  }
  
  .profile_sid123 .profile {
    font-weight: bold;
  }
  
  .profile_sid123 .banner {
    width: 100%;
    height: 40vh;
    
    background-size: cover;
  }
  
  .profile_sid123 .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #eeeded;
  }
  
  .profile_sid123 .profile-card {
    width: 100%;
    max-width: 300px;
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
    position: relative;
    top: -200px;
    background-color: white;
  }
  
  .profile_sid123 .profile-card h2 {
    margin-bottom: 3px;
  }
  
  .profile_sid123 .profile-card h5 {
    margin: 7px 0px 16px 0px;
    color: #020100;
    font-weight: 400;
    font-size: 16px;
  }
  
  .profile_sid123 .profile-card p {
    display: flex;
    gap: 10px; /* Adjust gap as needed */
    text-align: left; /* Align text to the left */
    align-items: center;
    color: #020100;
    margin: 10px 0px 0px 0px;
  }
  
  .profile_sid123 .profile-pic {
    width: 100%;
    height: 300px;
    object-fit: contain; /* Ensures the entire image is visible within the area */
    background-color: #f1f1f1; /* Background color for padding areas */
    display: block;
  }
  .profile_sid123 .p-img-btn{
    display: flex;
    gap: 10px;
    align-items: center;
    background-color: #FFFFFF;
    border: 10px solid white;
    cursor: pointer;
    margin: 5px;
    font-weight: 600;
  }
  .profile_sid123 .p-img-btn  button{
  border: none;
    background: none;
    color: black;
    margin: 0px;
    padding: 0px;
    border-radius: 0px;
  }
  
  .profile_sid123 button {
    background-color: rgb(23, 23, 44);
    color: white;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 10px;
    
  }
  
  .profile_sid123 .posts-section {
    width: 100%;
    margin-top: 20px;
  }
  
  .profile_sid123 .buttons {
    display: flex;
    justify-content: space-between;
   align-items: center;
  }
  .profile_sid123 .buttons-2 {
    display: flex;
    margin-bottom: 10px;
  }
  
  .profile_sid123 .filter-button {
    padding: 10px 20px;
    cursor: pointer;
    border: 1px solid #ccc;
    background-color:  rgb(23, 23, 44);
    transition: background-color 0.3s, color 0.3s;
  }

  .profile_sid123 .upload-button1{
    width: 100%;
    background-color: #0416F1;
    border-radius: 5px;
    border: none;
    margin: 0;
  }

  .profile_sid123 .but123{
    background: none;
    border-radius: 0px;
    border: none;
    color: black;
    margin-bottom: 10px;
    font-size: 16px;
    padding: 0;
    margin-top:0;
  }
  .profile_sid123 .name_align{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .profile_sid123 .img_edit img{
    margin-top:13px;
  }
  
  .profile_sid123 .filter-button:hover {
    /* background-color: #ddd; */
  }
  
  .profile_sid123 .filter-button.active {
    /* background-color: rgb(78, 81, 172);
    color: white;
    border-color: blue; */
    background: none;
  }

  .Line-profile{
    border-bottom: 2px solid #DDDDDD;
    margin-bottom: 16px;
  }
  
  .profile_sid123 .upload-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .profile_sid123 .upload-section input {
    margin-bottom: 10px;
  }
  /* .profile_sid123 .navbar-logo1{
    display: flex;
  } */
  
  .profile_sid123 .posts {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
    width: 100%;
    max-height: 100vh;
    overflow-y: auto;
  }
  
  .profile_sid123 .post1 {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
    overflow: hidden;
    transition: transform 0.3s;
    width: 100%;
    max-width: 300px;
    margin: 10px;
    background-color: white;
  }
  
  .profile_sid123 .post1:hover {
    transform: scale(1.05);
  }
  .profile_sid123 .edit_profile_btn{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .profile_sid123 .post123  {
    width: 100%;
    height: 200px;
    object-fit: contain;
    cursor: pointer;
  }
  
  .profile_sid123 .post1 iframe {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .profile_sid123 .card-overlay {
    position: absolute;
    top: 10px;
    right: 10px;
    display: none;
    flex-direction: column;
    gap: 5px;
  }
  
  .profile_sid123 .post1:hover .card-overlay {
    display: flex;
  }
  
  .profile_sid123 .card-overlay button {
    background-color: red;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .profile_sid123 .card-overlay button:hover {
    background-color: darkred;
  }
  
  .profile_sid123 .likes {
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
  }
  
  /* Responsive styles */
  @media (min-width: 600px) {
    .profile_sid123 .posts {
      /* grid-template-columns: repeat(2, 1fr); */
    }
  }
  
  @media (min-width: 900px) {
    .profile_sid123 .content {
      flex-direction: row;
      align-items: flex-start;

    }
  
    .profile_sid123 .profile-card {
      margin-right: 20px;
    }
  
    .profile_sid123 .posts {
      /* grid-template-columns: repeat(3, 1fr); */
      margin-left: 20px;
    }
  }
  
  @media (max-width: 900px) {
    .profile_sid123 .posts-section {
      position: relative;
      top: -150px;
    }
  }
  
  .profile_sid123 .full-image-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .profile_sid123 .full-image-modal img {
    max-width: 90%;
    max-height: 90%;
  }
  
  .profile_sid123 .close-button {
    position: absolute;
    top: 20px;
    right: 30px;
    font-size: 30px;
    color: white;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .profile_sid123 .close-button:hover {
    color: #ccc;
  }

  .profile_sid123  .ul_profile{
    margin: 5px;
    color: #020100;
  }
  
  .profile_sid123 .about {
    display: flex;
    justify-content: space-around; /* Align items horizontally */
    gap: 20px; 
    padding: 0;/* Adjust the gap between items */
    align-items: center;
  }
  
  
  
  
  .profile_sid123 .about li {
    display: flex;
    flex-direction: column;
    align-items: center; /* Align items vertically */
  }
  
  .profile_sid123 .about li span {
    font-weight: 600;
    font-size: 24px; 
    margin-bottom: 5px;/* Optionally, make the count bold */
  }
  
  
  .profile_sid123 .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5); /* Black w/ opacity */
  }
  
  /* Modal content */
  .profile_sid123 .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 500px;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    animation: fadeIn 0.3s ease;
  }
  
  /* Close button */
  .profile_sid123 .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .profile_sid123 .close:hover,
  .profile_sid123 .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
  /* Keyframe animation for modal appearance */
  @keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
  }
  
  /* Centered content */
  .profile_sid123 .modal-content h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .profile_sid123 .modal-content ul {
    list-style: none;
    padding: 0;
  }
  
  .profile_sid123 .modal-content ul li {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  /* Responsive styles */
  @media screen and (max-width: 600px) {
    /* Adjust modal width for smaller screens */
    .profile_sid123 .modal-content {
      width: 90%;
    }
  }


  .profile_sid123 .edit-profile-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 30px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
  }
  
  .profile_sid123 .edit-profile-form {
    display: flex;
    flex-direction: column;
    max-height: 80vh; /* Limit height to 80% of the viewport */
    overflow-y: auto; /* Enable vertical scrolling */
    padding: 20px;
  }
  
  .profile_sid123 .form-group {
    margin-bottom: 25px;
    position: relative;
  }
  
  .profile_sid123 label {
    font-weight: bold;
    margin-bottom: 8px;
    display: block;
    color: #333;
  }
  
  .profile_sid123 input[type="file"] {
    margin-bottom: 10px;
  }
  
  .profile_sid123 .text-input, .text-area {
    width: 100%;
    padding: 2px 0;
    border: none;
    border-bottom: 1px solid #ccc;
    font-size: 16px;
    line-height: 24px;
    transition: border-color 0.3s;
    outline: none;
  }
  
  .profile_sid123 .text-input:focus, .text-area:focus {
    border-bottom: 1px solid #007bff;
  }
  
  .profile_sid123 .text-area {
    resize: none;
  }
  
  .profile_sid123 .preview-image {
    width: 100px;
    height: 100px;
    margin-top: 10px;
    border-radius: 50%;
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .profile_sid123 .submit-button {
    padding: 12px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    transition: background-color 0.3s, transform 0.3s;
    align-self: center;
    width: 50%;
  }
  
  .profile_sid123 .submit-button:hover {
    background-color: #218838;
    transform: translateY(-2px);
  }
  
  .profile_sid123 .submit-button:active {
    background-color: #1e7e34;
    transform: translateY(0);
  }
  
  /* Responsive adjustments for smaller screens */
  @media (max-width: 600px) {
    .profile_sid123 .edit-profile-form {
      max-height: 70vh; /* Adjust height for smaller screens */
      padding: 15px;
    }
  
    .profile_sid123 .text-input, .text-area, .submit-button {
      font-size: 14px;
    }
  
    .profile_sid123 .submit-button {
      width: 100%; /* Ensure the submit button takes full width on smaller screens */
    }
  
    .profile_sid123 .preview-image {
      width: 80px;
      height: 80px;
    }
  }
  
  
  
  
  