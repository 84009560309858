 .share-button-wrapper {
    text-align: center;
    margin-top: 10px;
  }
  
  .image_setting{
    width: 100%;
    height: 30px;
  }
  
   .main-share-button:hover {
    background-color: #0056b3;
  }
  
  .share-options {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  
   .share-button {
    margin: 0 10px;
    transition: transform 0.3s ease;
  }
  
   .share-button:hover {
    transform: scale(1.1);
  }
  
  .share-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 400px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  .share-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
.share-modal-title {
    text-align: center;
    margin-bottom: 20px;
  }
  
   .close-modal-button {
    display: block;
    margin: 0 auto;
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
 .close-modal-button:hover {
    background-color: #0056b3;
  }
  