.sid_modal .modal {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
    justify-content: center;
    align-items: center;
  }
  
  .sid_modal .modal.show {
    display: flex;
  }
  
  .sid_modal .modal-content {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    border-radius: 8px;
    text-align: center;
    max-width: 400px;
    width: 80%;
  }
  
  .sid_modal .close-button {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .sid_modal .close-button:hover,
  .sid_modal .close-button:focus {
    color: black;
    text-decoration: none;
  }
  
  .sid_modal .modal-options {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
  }
  
  .sid_modal .option-button {
    background: none;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    color: #000;
    margin: 5px 0;
  }
  
  .sid_modal .option-button img {
    width: 24px;
    height: 24px;
    margin-bottom: 5px;
  }
  
  .sid_modal .upload-button1 {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
    margin-top: 10px;
  }

  .progress-bar {
    width: 100%;
    height: 20px;
    background-color: #f0f0f0;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 10px;
    position: relative;
}

.progress {
    height: 100%;
    background-color: #4caf50;
    width: 0;
    transition: width 0.3s ease;
    text-align: center;
    line-height: 20px;
    color: white;
    font-weight: bold;
    position: relative;
}
.iframe-demo code {
  display: block;
  white-space: pre-wrap;
}

.iframe-demo .highlight {
  background-color: yellow;
  font-weight: bold;
}


.moving-image {
    position: absolute;
    top: -5px; /* Adjust this value based on the size of your image */
    width: 30px; /* Adjust the width of your image */
    height: 30px; /* Adjust the height of your image */
    transition: transform 0.3s ease;
    z-index: 1;
}
  
  .sid_modal .upload-button1:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  /* Media Queries for Responsiveness */
  @media (max-width: 768px) {
    .sid_modal .modal-content {
      width: 90%;
    }
  
    .sid_modal .option-button {
      font-size: 12px;
    }
  
    .sid_modal .option-button img {
      width: 20px;
      height: 20px;
    }
  }
  
  @media (max-width: 480px) {
    .sid_modal .modal-content {
      width: 95%;
    }
  
    .sid_modal .option-button {
      font-size: 12px;
    }
  }
  