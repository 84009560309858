@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

/* Importing Montserrat */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');


.timeline_sid .container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: Arial, sans-serif;
}

 .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #08000E;
  color: white;
  position: relative;
  font-family: 'Montserrat', sans-serif;
}

 .navbar-logo {
  font-size: 24px;
  font-weight: bold;
  text-decoration: none;
}

.navbar-logo1 {
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 10px;
}

 .navbar-links {
  display: flex;
}

 .navbar-links a {
  margin: 0 15px;
  text-decoration: none;
  color: white;
}

.mobile-navbar-links a.active, .navbar-links a.active {
  color: white;
  background: none;
  font-weight: bold;
} 
  


.timeline_sid .sidebar.open {
  transform: translateX(0);
  width: 320px;
}
.timeline_sid .selected_type{
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  color:#6F758A
}
.navbar-menu {
  display: none;
  cursor: pointer;
  font-size: 24px;
}

 .navbar-menu-icon {
  display: none;
  cursor: pointer;
  font-size: 24px;
}

 .mobile-navbar-links {
  display: none;
  flex-direction: column;
  background-color: #08000E;
  position: absolute;
  top: 50px;
  right: 20px;
  padding: 10px;
}

 .mobile-navbar-links a {
  color: white;
  padding: 5px 0;
  text-decoration: none;
}

 .mobile-navbar-links a:hover {
  text-decoration: underline;
}

 .search-results-sidebar {
  position: absolute;
  top: 60px; /* Adjust this value based on your navbar height */
  left: 0; /* Adjust this value based on your sidebar width */
  right: 0;
  max-height: 100vh; /* Max height 100vh */
  overflow-y: auto; /* Make it scrollable */
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensure it appears on top */
  padding: 10px;
  
}

/* Styling for each search result */
 .search-result {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

/* Profile logo styling */
 .search-result img.profile-logo {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 50%;
}

/* Profile name styling */
 .search-result .profile-name {
  font-weight: bold;
}

/* Button styling for closing the search results sidebar */
 .search-results-sidebar button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 10px;
}

 .search-result a {
  color: black;
  text-decoration: none; /* Remove underline */
  display: flex;
  align-items: center;
}

 .search-result:hover {
  background-color: #38d711; /* Add a hover effect */
  text-decoration: none; /* Ensure no underline on hover */
  cursor: pointer;
  border-radius: 4px;
  padding: 5px;
}

.timeline_sid .content {
  display: flex;
  flex: 1;
}

.timeline_sid .sidebar {
  width: 22%;
 
  padding: 1rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.timeline_sid .sidebar-section {
  margin-bottom: 2rem;
}
.timeline_sid .filter_apply{
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 0;
}
/* .timeline_sid h4{
  margin: 0;
} */
.timeline_sid .adjust{
  display: flex;
    justify-content: space-between;
    align-items: center;
 }
.timeline_sid .filters{
    margin-top: 20px;
    font-weight: bold;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.timeline_sid .filters,
.timeline_sid .sort {
  margin-bottom: 20px;
}

/* .timeline_sid .filters h3 {
  margin-bottom: 10px;
} */

.timeline_sid label {
  display: block;
  margin: 5px 0;
}

.timeline_sid button {
  display: block;
  /* width: 100%; */
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: none;
  /* background-color: #001f3f; */
  color: white;
  cursor: pointer;
}

.timeline_sid button:hover {
  /* background-color: #0056b3; */
}

.timeline_sid .sharing{
  display: flex;
  gap: 20px;
  color: black;
  font-size: 25px;
}
.timeline_sid .main-content {
  flex: 1;
  width: 80%;
  padding: 1rem;
 
   background-color: #f4f4f4;
  overflow-y: auto;
  height: 85vh;
}



.timeline_sid .top-works-container {
  padding: 20px;
   background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow-x: auto; /* Enable horizontal scrolling */
}



.timeline_sid .top-works {
  overflow: hidden; /* Clearfix for floated elements */
  padding-bottom: 20px; /* Space below the cards */
  background-color: #e9e9ee;
  border-radius: 10px;
  margin-top: 15px;
}

.timeline_sid .tabs {
  display: flex;
  justify-content: center;
}

.timeline_sid .tab {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  width: 100%;
  color: black;
  margin: 0px;
  font-family: 'Inter', sans-serif;
}

.timeline_sid .tab.active {
  background-color: #007bff;
  color: white;
  border-radius: 5px;
}

.timeline_sid .work-group {
  display: flex; /* Flex container for work-cards */
  gap: 0px; /* Gap between cards */
  overflow: auto;
  /* white-space: nowrap; */
  scroll-behavior: smooth;
  font-family: 'Montserrat', sans-serif;
}

.timeline_sid .work-group {
  scrollbar-width: none;
}

/* Hide scrollbar for WebKit browsers */
.timeline_sid .work-group::-webkit-scrollbar {
  display: none;
}

/* .timeline_sid  .scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 24px;
  z-index: 1;
  padding: 10px;
} */

/* .timeline_sid  .scroll-button.left {

  color: black;
  position: relative;
    left: -20px;
    top: 370px;
    z-index: 10;
    border-radius: 50%;

    background-color: #f44336;
}

.timeline_sid  .scroll-button.right {

 
  color: black;
  position: relative;
    left: 100%;
    top: -275px;
    z-index: 10;
    border-radius: 50%;

    background-color: #f44336;
}  */

.timeline_sid .card {
  width: 350px;
  margin: 1.5% 0% 1.5% 1.5%;
  padding: 0px 10px;
  /* text-align: center; */
  /* border-radius: 8px; */
  border: 1px solid #ccc;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: white;
  flex: 0 0 auto;
}

.timeline_sid .participate_logo{
  display: flex;
    margin-top: 20px;
    font-weight: bold;
    gap: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.timeline_sid .card .video-title {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid #ccc;
  justify-content: space-between;
}

.searching {
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-bar {
  display: flex;
  align-items: center;
  background-color: #333;
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
}

.search-icon {
  margin-right: 10px;
  color: white;
}

.search-input {
  background-color: #333;
  border: none;
  color: white;
  outline: none;
  width: 100%;
}

.search-input::placeholder {
  color: #bbb;
}

.timeline_sid .card .vedio_controls {
  display: flex;
  align-items: center;
}

.timeline_sid .card .video-title span {
  margin-right: 10px;
}

.timeline_sid .card iframe {
  border-bottom: 1px solid #ccc;
}

.timeline_sid .card-content {
  margin: 0px 5px 0px 5px;
  padding: 20px 20px 10px 20px;
  align-items: center;
}

.timeline_sid .likes {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.timeline_sid .sharing{
  display: flex;
  gap: 10px;
  align-items: center;
}

.line1{
 margin: 0px 5px 15px 5px;
  border-bottom: 2px solid #DDDDDD;
}
.descrpt{
  margin: 0px 10px 10px 10px;
  text-align: left;

}
.descrpt h3{
  margin: 10px 0px 10px 0px;
}
.read_me_more{
  cursor: pointer;
}


.timeline_sid .heart {
  color: red;
  margin-right: 5px;
}

.timeline_sid .footer {
  background-color: #001f3f;
  color: white;
  text-align: center;
  padding: 1rem;
  position: fixed;
  width: 100%;
  bottom: 0;
}

.timeline_sid .user-profile {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  padding: 1rem;
}

.timeline_sid .group_img {
  display: flex;
  gap: 10px;
  align-items: center;
  font-family: 'Inter', sans-serif;
}

.timeline_sid .posts-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
}

.timeline_sid .post {
  width: 700px; /* Fixed width for each card */
  /* Fixed height for each card */
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
   background-color: #ffffff;
  text-align: center;
  overflow: hidden; /* Ensure content does not overflow */
  display: flex; /* Use flexbox for vertical alignment */
  flex-direction: column; /* Stack elements vertically */
  justify-content: space-between; /* Space elements evenly within the container */
}

.timeline_sid .post-image {
  width: 100%;
  height: 400px;
  object-fit: contain;
}

.timeline_sid .post video {
  height: 400px;
}

.timeline_sid .post iframe {
  height: 400px;
}

.timeline_sid .post-header {
  font-weight: bold;
  margin-bottom: 10px;
}

.timeline_sid .media-container {
  flex: 1; /* Let media container take remaining space */
  position: relative;
  overflow: hidden;
}

.timeline_sid .post-media {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio and cover container */
}

.timeline_sid .youtube-video {
  width: 100%;
  height: 100%;
}

.timeline_sid .post-interactions {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.timeline_sid .like-icon {
  cursor: pointer;
}

.timeline_sid .like-icon.liked {
  color: red;
}

.timeline_sid .like-count {
  margin-left: 5px;
}

.timeline_sid .upload-works-container {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  /* width: 300px; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.timeline_sid .upload-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  cursor: pointer;
}


.timeline_sid .upload-content {
  /* width: 100%; */
  border: 1px solid #ccc;
  border-radius: 8px;
  /* text-align: center; */
  /* padding: 16px; */
}


.timeline_sid .dashed_line_try{
  border-bottom: 2px dashed #9A9A9A;
}

.timeline_sid .upload-content h3 {
  margin: 0 0 10px 0;
  font-size: 18px;
}

.timeline_sid .upload-content p {
  color: #777;
  font-size: 14px;
  margin: 10px 0px 10px 10px;
}

.timeline_sid .upload-options {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.timeline_sid .option-button {
  background: none;
  border: none;
  display: flex;
  /* flex-direction: column; */
  gap: 10px;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  color: black;
  margin: 0;
}

.timeline_sid .option-button img {
  width: 24px;
  height: 24px;
  margin-bottom: 5px;
}

.timeline_sid .image_upload_timeline {
  display: flex;
  gap: 10px;
  align-items: center;
}


.timeline_sid .image_upload_timeline h3 {
  margin-top: 10px;
}

@media (max-width: 560px) {
   .navbar-logo1 {
    display: none;
  }

  .timeline_sid .group_img h2 {
    font-size: 16px;
  }
  .timeline_sid .top-works {
    padding: 0px;
  }

}

@media (max-width: 1024px) {
  .timeline_sid .content {
    flex-direction: column;
  }
  .timeline_sid .sidebar.open {
    width: 320px;
  }

  .timeline_sid .card {
    width: 250px;
    margin: 15px;
    padding: 10px;
  }

  .timeline_sid .main-content {
    width: 90%;
  }

   .navbar-links {
    display: none;
  }

   .navbar-menu {
    display: block;
  }

  .navbar-menu-icon {
    display: block;
  }

  .mobile-navbar-links {
    display: flex;
    z-index: 1000;
  }

  .timeline_sid .sidebar {
    transform: translateX(-200%);
    position: absolute;
    height: auto;
    width: 50%;
    z-index: 1000;
    font-family: 'Inter', sans-serif;
    background-color: white;
  }

  .timeline_sid .sidebar.open {
    transform: translateX(0);
    background-color: white;
  }
}

.timeline_sid .display_details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.timeline_sid .profile_imag_atsch {
  display: flex;
  gap: 10px;
  align-items: center;
}

@media (max-width: 800px) {
  .timeline_sid .post {
    width: 500px; /* 2 cards per row on smaller screens */
  }
  .welcome {
    font-size: 10px;
  }
}

@media (max-width: 576px) {
  .timeline_sid .post {
    width: 320px; /* 2 cards per row on smaller screens */
  }

  .timeline_sid .post-image  {
    height: 200px;
  }

  .timeline_sid .post video {
    height: 200px;
  }

  .timeline_sid .post iframe {
    height: 200px;
  }
}

/* @media (max-width: 1350px) {
  .timeline_sid .upload-works-container {
    width: 220px;
  }
}

@media (max-width: 1023px) {
  .timeline_sid .upload-works-container {
    width: 300px;
  }
} */

@media (max-width: 660px) {
  .timeline_sid .upload-button {
    width: 24px;
    height: 24px;
    font-size: 20px;
  }

  /* .timeline_sid .upload-works-container {
    width: 300px;
  } */

  .timeline_sid .upload-content h3 {
    font-size: 16px;
  }

  .timeline_sid .upload-content p {
    font-size: 12px;
  }

  /* .timeline_sid .upload-options {
    flex-direction: column;
  } */

  .timeline_sid .option-button {
    font-size: 12px;
  }
}

/* @media (max-width: 650px) {
  .timeline_sid .upload-works-container {
    width: 250px;
  }
}

@media (max-width: 560px) {
  .timeline_sid .upload-works-container {
    width: 200px;
  }
}

@media (max-width: 460px) {
  .timeline_sid .upload-works-container {
    width: 170px;
  }
}

@media (max-width: 410px) {
  .timeline_sid .upload-works-container {
    width: 130px;
  }
}

@media (max-width: 310px) {
  .timeline_sid .upload-works-container {
    width: 90px;
  }
} */

@media (max-width: 768px) {
  .timeline_sid .tabs {
    flex-direction: column;
    align-items: center;
  }

  .timeline_sid .tab {
    width: 100%;
    text-align: center;
    padding: 15px;
  }

 
}

@media (max-width: 480px) {
  .timeline_sid .tab {
    padding: 10px;
    font-size: 14px;
  }

 
}
