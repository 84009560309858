/* ForgotPasswordModal.css */
.forgot .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.forgot .modal-content {
    background: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    position: relative;
    width: 100%;
    max-width: 500px;
    text-align: center;
}

.forgot span .close-button {
    position: absolute;
    top: 10px;
    right:35px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #333;
    transition: color 0.2s;
    margin: 0px;
    padding: 0px;
    width:15px;
}

.forgot .close-button:hover {
    color: #ff0000;
}

.forgot form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.forgot form label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: bold;
    font-size: 14px;
}

.forgot form input {
    padding: 10px;
    margin-top: 5px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
}

.forgot form button {
    padding: 10px 15px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.forgot form button:hover {
    background-color: #0056b3;
}

.forgot h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
}
