.marquee-container {
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    display: flex;
    position: relative;
  }
  
  .marquee {
    display: flex;
    animation: marquee 20s linear infinite;
  }
  
  .marquee img {
    width: 25%; /* Adjust based on the number of images to fit in the container */
    flex-shrink: 0;
  }
  
  @keyframes marquee {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-50%);
    }
  }
  