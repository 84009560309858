@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.Home1 {
margin: 0;
background-color: #000;
color: #eee;
font-family: 'Poppins', sans-serif;
font-size: 12px;
}


.Home1 a {
text-decoration: none;
color: white;
}

.Home1 .navbar-links{
  font-size: 17px;
}

.Home1 header {
width: 1140px;
max-width: 80%;
margin: auto;
height: 50px;
display: flex;
align-items: center;
position: relative;
z-index: 100;
}

header a {
color: #eee;
margin-right: 5px;
padding: 5px 10px;
transition: 0.2s;
}

a.active {
background: #14ff72cb;
border-radius: 2px;
}

header a:hover {
background: #14ff72cb;
border-radius: 2px;
}

/* slider section */
.slider {
height: 100vh;
margin-top: -30px;
/* width: 100vw; */
overflow: hidden;
position: relative;
}

.slider .list {
display: flex;
transition: transform 0.5s ease-in-out;
}

.slider .list .item {
min-width: 100%;
height: 100%;
position: absolute;
inset: 0 0 0 0;
}

.slider .list .item img {
width: 100%;
height: 100%;

}
.Home .content{
    display: flex;
    flex-direction: column;
}

.slider .list .item .content {
position: absolute;
top: 20%;
width: 1140px;
max-width: 80%;
left: 45%;
/* transform: translateX(-50%) translateY(30%); */
padding-right: 32%;
padding-left: 3% ;
box-sizing: border-box;
color: #fff;
text-shadow: 0 5px 10px #0004;
}

.slider .list .item .content .title{
  /* font-size: 10em; */
  font-weight: bold;
  line-height: 1.3em;
}
.slider .list .item .content .type {
  font-size: 24px;
  /* font-weight: bold; */
  line-height: 1.3em;
  /* color: #14ff72cb; */
}
.descript_flex{
  display: flex;
  gap: 60px;
}


.slider .list .item .content .description button {
  font-size: 20px;
    font-weight: bold;
    line-height: 1.3em;
    color: white;
    margin-top: 35px;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    background-color: transparent;
    cursor: pointer;
    /* border: 1px solid black */
  }

.slider .list .item .type {
/* color: #14ff72cb; */
}

.slider .list .item .button {
display: grid;
grid-template-columns: repeat(2, 130px);
grid-template-rows: 40px;
gap: 5px;
margin-top: 20px;
}

.slider .list .item .button button {
border: none;
background-color: #eee;
font-family: 'Poppins', sans-serif;
font-weight: 500;
cursor: pointer;
transition: 0.4s;
letter-spacing: 2px;
}

.slider .list .item .button button:hover {
letter-spacing: 3px;
}

.slider .list .item .button button:nth-child(2) {
background-color: transparent;
border: 1px solid #fff;
color: #eee;
}

/* Thumbnail Section */
.thumbnail {
position: absolute;
bottom: 50px;
left: 50%;
width: max-content;
z-index: 100;
display: flex;
gap: 20px;
}

.thumbnail .item {
width: 150px;
height: 220px;
flex-shrink: 0;
position: relative;
}

.thumbnail .item img {
width: 100%;
height: 100%;
object-fit: cover;
border-radius: 20px;
box-shadow: 5px 0 15px rgba(0, 0, 0, 0.3);
}

/* nextPrevArrows Section */
.nextPrevArrows {
position: absolute;
top: 80%;
right: 52%;
z-index: 100;
width: 300px;
max-width: 30%;
display: flex;
gap: 10px;
align-items: center;
}

.nextPrevArrows button {
width: 40px;
height: 40px;
border-radius: 50%;
background-color: #14ff72cb;
border: none;
color: #fff;
font-family: monospace;
font-weight: bold;
transition: 0.5s;
cursor: pointer;
}

.nextPrevArrows button:hover {
background-color: #fff;
color: #000;
}

/* Animation Part */
.slider .list .item:nth-child(1) {
z-index: 1;
}

/* animation text in first item */
.slider .list .item:nth-child(1) .content .title,
.slider .list .item:nth-child(1) .content .type,
.slider .list .item:nth-child(1) .content .description,
.slider .list .item:nth-child(1) .content .buttons {
transform: translateY(50px);
filter: blur(20px);
opacity: 0;
animation: showContent 0.5s 1s linear 1 forwards;
}

@keyframes showContent {
to {
transform: translateY(0px);
filter: blur(0px);
opacity: 1;
}
}

.slider .list .item:nth-child(1) .content .title {
animation-delay: 0.4s !important;
}

.slider .list .item:nth-child(1) .content .type {
animation-delay: 0.6s !important;
}

.slider .list .item:nth-child(1) .content .description {
animation-delay: 0.8s !important;
font-size: 20px;
}

.slider .list .item:nth-child(1) .content .buttons {
animation-delay: 1s !important;
}

/* Animation for next button click */
.slider.next .list .item:nth-child(1) img {
width: 150px;
height: 220px;
position: absolute;
bottom: 50px;
left: 50%;
border-radius: 30px;
animation: showImage 0.5s linear 1 forwards;
}

@keyframes showImage {
to {
bottom: 0;
left: 0;
width: 100%;
height: 100%;
border-radius: 0;
}
}

.slider.next .thumbnail .item:nth-last-child(1) {
overflow: hidden;
animation: showThumbnail 0.5s linear 1 forwards;
}

.slider.prev .list .item img {
z-index: 100;
}

@keyframes showThumbnail {
from {
width: 0;
opacity: 0;
}
}

.slider.next .thumbnail {
animation: effectNext 0.5s linear 1 forwards;
}

@keyframes effectNext {
from {
transform: translateX(150px);
}
}

/* Animation for prev button click */
.slider.prev .list .item:nth-child(2) {
z-index: 2;
}

.slider.prev .list .item:nth-child(2) img {
animation: outFrame 0.5s linear 1 forwards;
position: absolute;
bottom: 0;
left: 0;
}

@keyframes outFrame {
to {
width: 150px;
height: 220px;
bottom: 50px;
left: 50%;
border-radius: 20px;
}
}

.slider.prev .thumbnail .item:nth-child(1) {
overflow: hidden;
opacity: 0;
animation: showThumbnail 0.5s linear 1 forwards;
}

.slider.next .nextPrevArrows button,
.slider.prev .nextPrevArrows button {
pointer-events: none;
}

.slider.prev .list .item:nth-child(2) .content .title,
.slider.prev .list .item:nth-child(2) .content .type,
.slider.prev .list .item:nth-child(2) .content .description,
.slider.prev .list .item:nth-child(2) .content .buttons {
animation: contentOut 1.5s linear 1 forwards !important;
}

.navbar{
    z-index: 1000;
}

@keyframes contentOut {
to {
transform: translateY(-150px);
filter: blur(20px);
opacity: 0;
}
}

/* home.css */
.Home-2 {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
}

.slide-container {
  position: relative;
  width: 70%;
  overflow: hidden;
  margin-right: 20px;
}

.slides {
  display: flex;
  transition: transform 1s ease-in-out;
  width: 100%;
}

.slides img {
  width: 100%;
  height: auto;
  object-fit: cover;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.slides img.active {
  opacity: 1;
}

.about-content {
  width: 30%;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 10px;
  box-sizing: border-box;
}

.about-content h2 {
  margin-bottom: 10px;
}

.about-content p {
  margin-bottom: 0;
}

.img_layer{
  position: relative;
  top: 50px;
  left: 1340px;
  z-index: 10000;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .Home-2 {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .slide-container {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .about-content {
    width: 90%;
    padding: 15px;
  }

  .about-content h2 {
    font-size: 18px;
  }

  .about-content p {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .Home-2 {
    padding: 5px;
  }

  .slide-container {
    width: 100%;
    margin-bottom: 15px;
  }

  .about-content {
    width: 100%;
    padding: 10px;
  }

  .about-content h2 {
    font-size: 16px;
  }

  .about-content p {
    font-size: 12px;
  }
}

  
  .image-content-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
  }
  
  .image-content {
    width: 30%;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .image-content img {
    width: 100%;
    border-radius: 8px;
  }
  
  .image-content .content {
    margin-top: 20px;
  }
  
  .image-content h2 {
    font-size: 20px;
    color: #333;
  }
  
  .image-content h3 {
    font-size: 18px;
    color: #666;
    margin-top: 10px;
  }
  
  .image-content p {
    font-size: 16px;
    color: #777;
    margin-top: 10px;
  }
  
  .image-content p strong {
    color: #000;
  }
  
  .image-content p a {
    color: #007bff;
    text-decoration: none;
  }
  
  .image-content p a:hover {
    text-decoration: underline;
  }
  
  .celebrities-section {
    text-align: center;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .image-content-container {
      flex-direction: column;
      align-items: center;
    }
  
    .image-content {
      width: 80%;
      margin-bottom: 20px;
    }
  
    .image-content .content {
      margin-top: 15px;
    }
    .slider .list .item img{
      object-fit: cover;
    }
  
    .image-content h2 {
      font-size: 18px;
    }
  
    .image-content h3 {
      font-size: 16px;
    }
  
    .image-content p {
      font-size: 14px;
    }
  }
  
  @media (max-width: 480px) {
    .image-content {
      width: 90%;
      padding: 15px;
    }
  
    .image-content .content {
      margin-top: 10px;
    }
  
    .image-content h2 {
      font-size: 16px;
    }
  
    .image-content h3 {
      font-size: 14px;
    }
  
    .image-content p {
      font-size: 12px;
    }
  }
  
  .contact-us-section {
    text-align: center;
  }
  .contact-form-container {
    margin-top: 50px;
  }
  
  .contact-form {
    max-width: 400px;
    margin: 0 auto;
  }
  
  .contact-form label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #333;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 90%;
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s ease;
  }
  
  .contact-form input:focus,
  .contact-form textarea:focus {
    border-color: #007bff;
  }
  
  .contact-form textarea {
    resize: vertical;
  }
  
  .contact-form button {
    width: 100%;
    padding: 12px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .contact-form button:hover {
    background-color: #0056b3;
  }
  
  
  
/* test.css */


  
  


@media screen and (max-width: 678px) {
.slider .list .item .content {
padding-right: 0;
}
.slider .list .item .content .title {
font-size: 50px;
}
.slider .list .item:nth-child(1) .content .description{
    font-size: 15px;
}
}

.Home-2 body {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #ede6d6;
  }
  
  .Home-2 .slide-container {
    position: relative;
    /* width: 100%; */
    /* max-width: 800px; */
    height: 600px;
    border: 3px solid #ede6d6;
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.2);
    overflow: hidden;
  }
  
  .Home-2 .slides {
    width: 100%;
    height: 100%;
    position: relative;
  }
  
  .Home-2 .slides img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    transition: opacity 0.5s ease;
    opacity: 0;
  }
  
  .Home-2 .slides img.active {
    opacity: 1;
  }
  
  /* Media query for responsiveness */
  @media (max-width: 800px) {
    .Home-2 .slide-container {
        height: auto;
        aspect-ratio: 16 / 9;
    }
    .Home-2 .slides img {
      height: auto;
    }
  }

  @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");



.Team body {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #cbc9e8;
}

.Team .wrapper .title {
    text-align: center;
}

.Team .title h4 {
    display: inline-block;
    padding: 20px;
    color: #585757;
    font-size: 50px;
    font-weight: 500;
    letter-spacing: 1.2px;
    word-spacing: 5px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    text-transform: uppercase;
    backdrop-filter: blur(15px);
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    word-wrap: break-word;
}

.Team .wrapper .card_Container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 40px 0;
}

.Team .card_Container .card {
    position: relative;
    width: 300px;
    height: 400px;
    margin: 20px;
    overflow: hidden;
    box-shadow: 0 30px 30px -20px rgba(0, 0, 0, 1),
                inset 0 0 0 1000px rgba(67, 52, 109, .6);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Team .card .imbBx, .Team .card .imbBx img {
    width: 100%;
    height: 100%;
}

.Team .card .content {
    position: absolute;
    bottom: -160px;
    width: 100%;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    backdrop-filter: blur(15px);
    box-shadow: 0 -10px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    transition: bottom 0.5s;
    transition-delay: 0.65s;
}

.Team .card:hover .content {
    bottom: 0;
    transition-delay: 0s;
}

.Team .content .contentBx h3 {
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 2px;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    margin: 20px 0 15px;
    line-height: 1.1em;
    transition: 0.5s;
    transition-delay: 0.6s;
    opacity: 0;
    transform: translateY(-20px);
}

.Team .card:hover .content .contentBx h3 {
    opacity: 1;
    transform: translateY(0);
}

.Team .content .contentBx h3 span {
    font-size: 12px;
    font-weight: 300;
    text-transform: initial;
}

.Team .content .sci {
    position: relative;
    bottom: 10px;
    display: flex;
}

.Team .content .sci li {
    list-style: none;
    margin: 0 10px;
    transform: translateY(40px);
    transition: 0.5s;
    opacity: 0;
    transition-delay: calc(0.2s * var(--i));
}

.Team .card:hover .content .sci li {
    transform: translateY(0);
    opacity: 1;
}

.Team .content .sci li a {
    color: #fff;
    font-size: 24px;
}


@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.Footer body  {
    line-height: 1.5;
    font-family: 'Poppins', sans-serif;
}


.Footer .container {
    max-width: 1170px;
    margin: auto;
}

.Footer .row {
    display: flex;
    flex-wrap: wrap;
}

.Footer ul {
    list-style: none;
}

.Footer .footer {
    background-color: #24262b;
    padding: 70px 0;
}

.Footer .footer-col {
    width: 25%;
    padding: 0 15px;
}

.Footer .footer-col h4 {
    font-size: 18px;
    color: #ffffff;
    text-transform: capitalize;
    margin-bottom: 35px;
    font-weight: 500;
    position: relative;
}

.Footer .footer-col h4::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px;
    background-color: #e91e63;
    height: 2px;
    box-sizing: border-box;
    width: 50px;
}

.Footer .footer-col ul li:not(:last-child) {
    margin-bottom: 10px;
}

.Footer .footer-col ul li a {
    font-size: 16px;
    text-transform: capitalize;
    color: #ffffff;
    text-decoration: none;
    font-weight: 300;
    color: #bbbbbb;
    display: block;
    transition: all 0.3s ease;
}

.Footer .footer-col ul li a:hover {
    color: #ffffff;
    padding-left: 8px;
}

.Footer .footer-col .social-links a {
  display: inline-block;
  /* height: 40px; */
  width: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 10px 10px 0;
  text-align: center;
  /* line-height: 40px; */
  /* border-radius: 50%; */
  color: #ffffff;
  transition: all 0.5s ease;
}

.Footer .footer-col .social-links a:hover {
    color: #24262b;
    background-color: #ffffff;
}

/*responsive*/
 @media(max-width: 767px) {
    .Footer .footer-col {
        width: 50%;
        margin-bottom: 30px;
    }
}

 @media(max-width: 574px) {
    .Footer .footer-col {
        width: 100%;
    }
}


.e-store {
  text-align: center;
  margin-top: 50px;
}

.e-store h2 {
  margin-bottom: 20px;
}

.e-store-content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.e-store-item {
  width: 30%;
  margin-bottom: 30px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.e-store-item:hover {
  transform: translateY(-5px);
}

.e-store-item img {
  width: 100%;
  height: auto;
}

.e-store-item .content {
  padding: 20px;
}

.e-store-item h3 {
  margin-top: 0;
}

.e-store-item p {
  color: #666;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .e-store-content {
    flex-direction: column;
    align-items: center;
  }

  .e-store-item {
    width: 80%;
    margin-bottom: 20px;
  }

  .e-store-item img {
    width: 100%;
  }

  .e-store-item .content {
    padding: 15px;
  }

  .e-store-item h3 {
    font-size: 1.2em;
  }

  .e-store-item p {
    font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  .e-store h2 {
    font-size: 1.5em;
    margin-bottom: 15px;
  }

  .e-store-item {
    width: 90%;
    margin-bottom: 15px;
  }

  .e-store-item .content {
    padding: 10px;
  }

  .e-store-item h3 {
    font-size: 1em;
  }

  .e-store-item p {
    font-size: 0.8em;
  }
}

.blogs {
  margin-top: 50px;
}

.blogs h2 {
  text-align: center;
  margin-bottom: 20px;
}

.blogs-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.blog-item {
  width: calc(33.33% - 20px); /* Adjust width based on your design */
  margin-bottom: 20px;
}

.blog-item img {
  width: 100%;
  height: auto;
}

.blog-content {
  padding: 10px;
  background-color: #f9f9f9;
}

.blog-content h3 {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
}

.blog-content p {
  font-size: 14px;
  color: #777;
  line-height: 1.5;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .blogs-container {
    flex-direction: column;
    align-items: center;
  }

  .blog-item {
    width: 80%;
    margin-bottom: 20px;
  }

  .blog-content h3 {
    font-size: 16px;
  }

  .blog-content p {
    font-size: 13px;
  }
}

@media (max-width: 480px) {
  .blogs h2 {
    font-size: 1.5em;
    margin-bottom: 15px;
  }

  .blog-item {
    width: 100%;
    margin-bottom: 15px;
  }

  .blog-content h3 {
    font-size: 14px;
  }

  .blog-content p {
    font-size: 12px;
  }
}



