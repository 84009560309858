@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
.sid-register {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2c254a;
  padding: 10px 20px;
  color: white;
}

.navbar .logo {
  height: 50px;
}

.navbar nav a {
  margin: 0 10px;
  color: white;
  text-decoration: none;
}

.sid-register .container {
  display: flex;
  justify-content: center;
  padding: 0px 130px;
  margin-top: 20px;
  align-items: center
}

.sid-register .form-container, .benefits-container {
  background-color: #fff;
  padding: 20px;
}

.sid-register .form-container {
  flex: 1 1 45%;
  margin: 10px;
  max-width: 700px;
}

.sid-register .benefits-container {
  flex: 1 1 45%;
  margin: 10px;
  text-align: center;
}

.sid-register .benefits-container img.benefits-image {
  width: 100%;
  margin: 0 auto;
  display: block;
  height: 95%;
}

.sid-register h2, h3 {
  color: #333;
  font-weight: bold;
}

.sid-register form {
  display: flex;
  flex-direction: column;
}
.sid-register .navbar-logo1{
  display: flex;
}

.sid-register label {
  margin-top: 20px;
  font-weight: 600;
}

.sid-register input, select, textarea {
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

/* Add the following styles */

.sid-register .password-container {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .sid-register .password-container input {
    width: 100%;
    padding-right: 40px;
  }
  
  .sid-register .password-container .fa-eye,
  .sid-register .password-container .fa-eye-slash {
    position: absolute;
    right: 10px;
  }
  

.sid-register .checkbox-container {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.sid-register .checkbox-container input {
  margin-right: 10px;
}

.sid-register button {
  background-color: #5e00f3;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 15px;
}

.sid-register button:hover {
  background-color: #4b00c1;
}

.sid-register .otp-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.sid-register .otp-container input {
  flex: 1;
  padding: 10px;
}

.sid-register .otp-container button {
  flex: none;
}



.sid-register .benefits {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.sid-register .benefits span {
  background-color: #a463f2;
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
}

@media (max-width: 1150px) {
    .sid-register .container {
    flex-direction: column;
    align-items: center;
  }
  .sid-register .form-container, .benefits-container {
    flex: 1 1 100%;
  }
  .sid-register .benefits-container {
    order: -1;
  }
}

@media (max-width: 700px) {
    .sid-register .container {
    padding: 0px;
  }
}
