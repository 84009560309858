@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.app-container {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Montserrat', sans-serif;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.logo_name_2{
  font-weight: bold;
}



.app-container .container {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.app-container .sidebar {
  width: 25%;
  background-color: #FFFFFF;
  padding: 20px;
  box-sizing: border-box;
}

.sidebar.open {
  width: 100%;
    padding: 10px;
    transform: translateX(0px);
    z-index:1000;
}

.app-container .sidebar h2 {
  margin-bottom: 20px;
}

.app-container .sidebar ul {
  padding: 0;
}

.app-container .sidebar ul li {
  padding: 10px;
  transition: background-color 0.3s;
  color: #020100;
  
}

#border_try {
  
  list-style: none;
}
.line{
border-bottom: 2px solid #BBBBBB;
}

.align_sublist {
  display: flex;
  justify-content: center;
}
.align_sublist ul li{
  cursor: pointer;
  list-style-type: disc; 
}



/* .app-container .sidebar ul li:hover {
  background-color: #e0e0e0;
} */

.drop_down {
  cursor: pointer;
}


.app-container .sidebar ul li.active-background {
  background-color: #D9E5FF;
  border-radius: 2%;
  margin-bottom: 10px;
}
.selected-subitem {
  font-weight: bold;
  padding: 5px; 
}
li::marker{
  font-size: 14px;
}
li.selected-subitem::marker {
  font-size: 22px; 
}

.logo-name {
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.logo_name_2 {
  display: flex;
  align-items: center;
  gap: 30px;
}

.app-container .main-content {
  width: 82%;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  background: #eeeded;
  position: relative;
}

.app-container .content-participate {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.app-container .main-content h2 {
  margin-bottom: 20px;
}

.app-container .participate-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 25px;
  /* width: 15%; */
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 16px;
}

.app-container .participate-button:hover {
  background-color: #0056b3;
}

.app-container .gallery {
  display: flex;
  flex-wrap: wrap;
}

.app-container .card {
  width: 30%;
  margin: 1.5%;
  border: 1px solid #ccc;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.app-container .card .video-title {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid #ccc;
  justify-content: space-between;
}

.app-container .card .vedio_controls {
  display: flex;
  align-items: center;
}

.app-container .card .video-title span {
  margin-right: 10px;
}

.app-container .card iframe {
  border-bottom: 1px solid #ccc;
}

.app-container .card-content {
  margin: 0px 5px 0px 5px;
  padding: 20px 20px 10px 20px;
  align-items: center;
}

.app-container .likes {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.app-container .sharing{
  display: flex;
  gap: 10px;
  align-items: center;
}

.line1{
 margin: 0px 5px 15px 5px;
  border-bottom: 2px solid #DDDDDD;
}
.descrpt{
  margin: 0px 10px 10px 10px;
}
.descrpt h3{
  margin: 10px 0px 10px 0px;
}
.read_me_more{
  cursor: pointer;
  font-weight: bold;
}

.app-container .heart {
  color: red;
  margin-right: 5px;
  font-size: 24px;
}

.app-container .voting {
  text-align: center;
  padding-bottom: 10px;
}

.app-container .vote-now-button {
  border-radius: 10px;
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.app-container .vote-now-button:hover {
  background-color: #2980b9;
}

.app-container .popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden; /* Prevent body scroll */
}

.app-container .popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  max-width: 90%;
  max-height: 80vh; /* Limit the height to 80% of the viewport */
  overflow-y: auto; /* Enable vertical scrolling if content exceeds the height */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.app-container .popup-content .close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

.app-container .popup-content h2 {
  margin-top: 0;
}

.app-container .form-group {
  margin-bottom: 15px;
}

.app-container .form-group label {
  display: block;
  margin-bottom: 5px;
}

.app-container .form-group input,
.app-container .form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.app-container .form-group textarea {
  resize: vertical;
  height: 100px;
}

.app-container .popup-content button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.app-container .popup-content button:hover {
  background-color: #0056b3;
}

/* Add responsive adjustments */
@media (max-width: 600px) {
  .app-container .popup-content {
    width: 95%;
    padding: 15px;
  }

  .app-container .popup-content .close {
    font-size: 20px;
  }

  .app-container .form-group input,
  .app-container .form-group textarea,
  .app-container .popup-content button {
    font-size: 14px;
  }
}


.app-container .delete-button {
  background-color: #ff4d4d;
  border: none;
  color: white;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin-left: 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.app-container .delete-button:hover {
  background-color: #ff1a1a;
}

.app-container .delete-button:focus {
  outline: none;
}

.app-container .delete-button:active {
  background-color: #e60000;
}

.custom-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.inside_line{
  border-bottom: 2px dashed #5C7ECA;
  margin-bottom: 5px;
}

.custom-popup-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  max-width: 600px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.custom-popup-description {
  margin-top: 20px;
}

.custom-close-button {
  display: block;
  margin: 20px auto 0;
  padding: 10px 20px;
  background: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

@media (max-width: 1350px) {
  
  .app-container .sidebar {
    width: 30%;
    z-index:1000;
  }
  .app-container .card {
    width: 40%;
  }
}
@media (max-width: 1200px) {
  
  .app-container .sidebar {
    width: 35%;
    z-index:1000;
  }
}

@media (max-width: 1024px) {
  
  .app-container .sidebar {
    transform: translateX(-100%);
    position: absolute;
    height: 90%;
    width: 50%;
    z-index: 1000;
    font-family: 'Inter', sans-serif;
    background-color: white;
    padding: 10px;
    box-sizing: inherit;
   
  }
  .app-container .main-content {
    width: 100%;
  }


   .sidebar.open {
    transform: translateX(0);
    background-color: white;
    width: 80%;
    z-index:1000;
  }
}



@media only screen and (max-width: 1200px) {
  .app-container .gallery {
    margin: 10px;
  }

  .app-container .card {
    width: calc(45% - 30px); /* Adjust card width for three-column layout */
    margin: 15px;
  }
}

@media only screen and (max-width: 768px) {
  .app-container .gallery {
    flex-direction: column;
    align-items: center;
    width: 110%;
    margin: 0px;
    display: block;
  }

  .app-container .card {
    width: 90%; /* Full width cards on smaller screens */
    margin: 15px 0;
  }

  .app-container .participate-button {
    width: 100%;
    max-width: none;
  }

  .app-container .vote-now-button {
    max-width: none;
  }
}

@media only screen and (max-width: 480px) {
  .app-container .popup-content {
    width: 100%;
    max-width: 100%;
  }
  .app-container .content-participate {
    display: contents;
  }
}

