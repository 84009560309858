@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=League+Spartan&family=Volkhov&family=Chonburi&family=Montserrat&family=Press+Start+2P&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');


.Home1{
    margin: 0;
    background-color: #000;
    color: #eee;
    font-family: Poppins;
    font-size: 12px;
}
.Home1 a{
    text-decoration: none;
}
.Home1 header{
    /* width: 1140px; */
    /* max-width: 80%; */
    margin: auto;
    height: 50px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 100;

}
.Home1 header a{
    color: #eee;
    margin-right: 40px;
}

/* carousel */
.Home1 .carousel{
    height: 100vh;
    margin-top: 0px;
    /* width: 100vw; */
    overflow: hidden;
    position: relative;
}
.Home1 .carousel .list .item{
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0 0 0 0;
}
.Home1 .carousel .list .item img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.Home1 .carousel .list .item .content{
    position: absolute;
    display: flex;
    flex-direction: column;

    /* top: 20%; */
    /* width: 1340px; */
    /* max-width: 80%; */
    /* left: 40%; */
    /* transform: translateX(-50%); */
    justify-content: end;
    padding-right: 30%;
    box-sizing: border-box;
    color: #fff;
    text-shadow: 0 5px 10px #0004;
    font-family: 'Press Start 2P',sans-serif;
    /* z-index: 1000; */
}
.Home1 .carousel .list .item .author{
    font-weight: bold;
    letter-spacing: 10px;
}
.Home1 .carousel .list .item .title,
.Home1 .carousel .list .item .topic{
    font-size: 5em;
    font-weight: bold;
    line-height: 1.3em;
}
/* .Home1 .carousel .list .item .topic{
    color: #f1683a;
} */
.Home1 .carousel .list .item  .buttons{
    display: grid;
    grid-template-columns: repeat(2, 130px);
    grid-template-rows: 40px;
    gap: 5px;
    margin-top: 20px;
    justify-content: end;
    

}
.Home1 .carousel .list .item .buttons  button{
    border: none;
    background-color: #eee;
    letter-spacing: 3px;
    font-family: Poppins;
    font-weight: 500;
}
.Home1 .carousel .list .item .buttons button:nth-child(2){
    background-color: transparent;
    border: 1px solid #fff;
    color: #eee;
}
/* thumbail */
.Home1 .thumbnail{
    position: absolute;
    bottom: 50px;
    left: 50%;
    width: max-content;
    z-index: 100;
    display: flex;
    gap: 20px;
}
.Home1 .thumbnail .item{
    width: 150px;
    height: 220px;
    flex-shrink: 0;
    position: relative;
}
.Home1 .thumbnail .item img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}
.Home1 .thumbnail .item .content{
    color: #fff;
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
}
.Home1 .thumbnail .item .content .title{
    font-weight: 500;
}
.Home1 .thumbnail .item .content .description{
    font-weight: 300;
}
/* arrows */
.Home1 .arrows{
    position: absolute;
    top: 80%;
    right: 52%;
    z-index: 100;
    width: 300px;
    max-width: 30%;
    display: flex;
    gap: 10px;
    align-items: center;
    
}

.Home1 .navbar-logo1{
display: flex;
}
.Home1 .arrows button{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(73, 73, 73, 0.267);
    border: none;
    color: #fff;
    font-family: monospace;
    font-weight: bold;
    transition: .5s;
}
.Home1 .arrows button:hover{
    background-color: #fff;
    color: #000;
}

.img_layer{
    position: relative;
    top: 1000px;
    left: 1340px;
    z-index: 10000;
  }

/* animation */
.Home1 .carousel .list .item:nth-child(1){
    z-index: 1;
}

/* animation text in first item */

.Home1 .carousel .list .item:nth-child(1) .content .author,
.Home1 .carousel .list .item:nth-child(1) .content .title,
.Home1 .carousel .list .item:nth-child(1) .content .topic,
.Home1 .carousel .list .item:nth-child(1) .content .des,
.Home1 .carousel .list .item:nth-child(1) .content .buttons
{
    transform: translateY(50px);
    filter: blur(20px);
    opacity: 0;
    animation: showContent .5s 1s linear 1 forwards;
}
@keyframes showContent{
    to{
        transform: translateY(0px);
        filter: blur(0px);
        opacity: 1;
    }
}
.Home1 .carousel .list .item:nth-child(1) .content .title{
    animation-delay: 1.2s!important;
}
.Home1 .carousel .list .item:nth-child(1) .content .topic{
    animation-delay: 1.4s!important;
}
.Home1 .carousel .list .item:nth-child(1) .content .des{
    animation-delay: 1.6s!important;
}
.Home1 .carousel .list .item:nth-child(1) .content .buttons{
    animation-delay: 1.8s!important;
}
/* create animation when next click */
.Home1 .carousel.next .list .item:nth-child(1) img{
    width: 150px;
    height: 220px;
    position: absolute;
    bottom: 50px;
    left: 50%;
    border-radius: 30px;
    animation: showImage .5s linear 1 forwards;
}
@keyframes showImage{
    to{
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 0;
    }
}

.Home1 .carousel.next .thumbnail .item:nth-last-child(1){
    overflow: hidden;
    animation: showThumbnail .5s linear 1 forwards;
}
.Home1 .carousel.prev .list .item img{
    z-index: 100;
}
@keyframes showThumbnail{
    from{
        width: 0;
        opacity: 0;
    }
}
.Home1 .carousel.next .thumbnail{
    animation: effectNext .5s linear 1 forwards;
}

@keyframes effectNext{
    from{
        transform: translateX(150px);
    }
}

/* running time */

.Home1 .carousel .time{
    position: absolute;
    z-index: 1000;
    width: 0%;
    height: 3px;
    /* background-color: #f1683a; */
    left: 0;
    top: 0;
}

.Home1 .carousel.next .time,
.Home1.carousel.prev .time{
    animation: runningTime 3s linear 1 forwards;
}
@keyframes runningTime{
    from{ width: 100%}
    to{width: 0}
}


/* prev click */

.Home1 .carousel.prev .list .item:nth-child(2){
    z-index: 2;
}

.Home1 .carousel.prev .list .item:nth-child(2) img{
    animation: outFrame 0.5s linear 1 forwards;
    position: absolute;
    bottom: 0;
    left: 0;
}
@keyframes outFrame{
    to{
        width: 150px;
        height: 220px;
        bottom: 50px;
        left: 50%;
        border-radius: 20px;
    }
}

.Home1 .carousel.prev .thumbnail .item:nth-child(1){
    overflow: hidden;
    opacity: 0;
    animation: showThumbnail .5s linear 1 forwards;
}
.Home1 .carousel.next .arrows button,
.Home1 .carousel.prev .arrows button{
    pointer-events: none;
}
.Home1 .carousel.prev .list .item:nth-child(2) .content .author,
.Home1 .carousel.prev .list .item:nth-child(2) .content .title,
.Home1.carousel.prev .list .item:nth-child(2) .content .topic,
.Home1 .carousel.prev .list .item:nth-child(2) .content .des,
.Home1 .carousel.prev .list .item:nth-child(2) .content .buttons
{
    animation: contentOut 1.5s linear 1 forwards!important;
}



@keyframes contentOut{
    to{
        transform: translateY(-150px);
        filter: blur(20px);
        opacity: 0;
    }
}
@media screen and (max-width: 678px) {
    .Home1 .carousel .list .item .content{
        padding-right: 0;
    }
    .Home1 .carousel .list .item .content .title{
        font-size: 30px;
    }
}


@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.Footer body  {
    line-height: 1.5;
    font-family: 'Poppins', sans-serif;
}


.Footer .container {
    max-width: 1170px;
    margin: auto;
}

.Footer .row {
    display: flex;
    flex-wrap: wrap;
}

.Footer ul {
    list-style: none;
}

.Footer .footer {
    background-color: #24262b;
    padding: 70px 0;
}

.Footer .footer-col {
    width: 25%;
    padding: 0 15px;
}

.Footer .footer-col h4 {
    font-size: 18px;
    color: #ffffff;
    text-transform: capitalize;
    margin-bottom: 35px;
    font-weight: 500;
    position: relative;
}

.Footer .footer-col h4::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px;
    background-color: #e91e63;
    height: 2px;
    box-sizing: border-box;
    width: 50px;
}

.Footer .footer-col ul li:not(:last-child) {
    margin-bottom: 10px;
}

.Footer .footer-col ul li a {
    font-size: 16px;
    text-transform: capitalize;
    color: #ffffff;
    text-decoration: none;
    font-weight: 300;
    color: #bbbbbb;
    display: block;
    transition: all 0.3s ease;
}

.Footer .footer-col ul li a:hover {
    color: #ffffff;
    padding-left: 8px;
}

.Footer .footer-col .social-links a {
    display: inline-block;
    /* height: 40px; */
    width: 40px;
    background-color: rgba(255, 255, 255, 0.2);
    margin: 0 10px 10px 0;
    text-align: center;
    /* line-height: 40px; */
    /* border-radius: 50%; */
    color: #ffffff;
    transition: all 0.5s ease;
}

.Footer .footer-col .social-links a:hover {
    color: #24262b;
    background-color: #ffffff;
}

/*responsive*/
 @media(max-width: 767px) {
    .Footer .footer-col {
        width: 50%;
        margin-bottom: 30px;
    }
}

 @media(max-width: 574px) {
    .Footer .footer-col {
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .Home1 .thumbnail {
      display: none;
    }
  }
